import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import uniqid from 'uniqid';
import Case from 'case';

import { Box, Text } from 'grommet';

import { CurrentDateContext } from '@Components/Context';
import { withProductAuth } from '@Components/Layout';
import {
  Seo, RadioButtonSlider, AppButton, Spinning,
} from '@Components/Control';
import { AuthNavWrapper } from '@Components/Navigation';
import {
  ProductFormFieldContainer,
  ProductFormDropdownSelect,
  ProductFormSectionWrapper,
  ProductFormSingleTextInput,
} from '@Components/Partial/Product/NewSearch';

import {
  initiateNewFormConfigRequest,
  submitNewSearchRequest,
} from '@Actions';
import { paths } from '@Components/configs';

import DynamicFormRenderer from './DynamicFormRenderer';


const BannerChildren = ({
  bgColor, textColor, setBulkSearch,
}) => (
  <Box direction="row" justify="between">
    <RadioButtonSlider
      radioOptions={[{ label: 'Individual', value: 'individual' }, { label: 'Bulk search', value: 'bulk' }]}
      componentBg={bgColor}
      textColor={textColor}
      activeButtonBg="white"
      updateParent={setBulkSearch}
    />
  </Box>
);

BannerChildren.propTypes = {
  bgColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  setBulkSearch: PropTypes.func.isRequired,
};

const NewSearchPage = ({
  small,
  mixpanel,
  location,
  loading,
  fetchFormConfig,
  submitNewSearch,
  formConfig = null,
  authPagesConfig = null,
  reportsConfig = null,
  cyclopsConfig = null,
}) => {
  const [searchType, setSearchType] = React.useState(null);
  const [searchName, setSearchName] = React.useState(null);
  const [formConfigResult, setFormConfigResult] = React.useState(null);
  const [requiredFormInputs, setRequiredFormInputs] = React.useState(null);
  const [optionalFormInputs, setOptionalFormInputs] = React.useState(null);
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    searchType: null, searchFieldValues: {}, resetKey: uniqid(),
  });
  const { today } = React.useContext(CurrentDateContext);
  const searchTypeKey = formConfig?.searchTypes && searchType
    && Case.camel(_.find(formConfig.searchTypes, { name: searchType })?.category);

  React.useEffect(() => {
    if (!formConfig) {
      fetchFormConfig();
    }

    if (formConfig && !searchType) {
      setSearchType(formConfig.searchTypes[0].name);
    }
  }, [formConfig]);

  React.useEffect(() => {
    if (formConfig && searchType) {
      setFormConfigResult(_.find(formConfig.searchTypes, { name: searchType }).fieldData);
      setFormValues({ searchType, searchFieldValues: {}, resetKey: uniqid() });
      setSearchName(null);
    }
  }, [searchType]);

  React.useEffect(() => {
    if (formConfigResult) {
      const partitionedResults = _.partition(formConfigResult, 'required');
      const [requiredInputs, optionalInputs] = partitionedResults;

      const filteredRequiredInputs = _.filter(requiredInputs, (({ config }) => (
        !config?.supportedCategoryPlatforms && !config?.supportedRecurrence)));
      const filteredOptionalInputs = _.filter(optionalInputs, (({ config }) => (
        !config?.supportedCategoryPlatforms && !config?.supportedRecurrence)));

      setRequiredFormInputs(filteredRequiredInputs);
      setOptionalFormInputs(filteredOptionalInputs);
    }
  }, [formConfigResult]);

  const checkInputRequirements = (config) => {
    const selectedPlatforms = formValues.searchFieldValues.platform;
    const selectedRecurrence = formValues.searchFieldValues.recurrence;
    const currentPlatformConfig = config?.supportedCategoryPlatforms
      && config.supportedCategoryPlatforms[searchTypeKey];

    if (currentPlatformConfig && config?.supportedRecurrence) {
      return selectedPlatforms?.some((p) => currentPlatformConfig.includes(p))
        && (config.supportedRecurrence === selectedRecurrence);
    }

    if (currentPlatformConfig) {
      return selectedPlatforms?.some((p) => currentPlatformConfig.includes(p));
    }

    if (config?.supportedRecurrence) {
      return (config.supportedRecurrence === selectedRecurrence);
    }

    return true;
  };

  React.useEffect(() => {
    if (formConfigResult) {
      const partitionedResults = _.partition(formConfigResult, 'required');
      const [requiredInputs, optionalInputs] = partitionedResults;

      const filteredRequiredInputs = _.filter(requiredInputs, (({ config }) => (
        checkInputRequirements(config))));
      const filteredOptionalInputs = _.filter(optionalInputs, (({ config }) => (
        checkInputRequirements(config))));

      setRequiredFormInputs(filteredRequiredInputs);
      setOptionalFormInputs(filteredOptionalInputs);
    }
  }, [formValues.searchFieldValues]);

  React.useEffect(() => {
    if (requiredFormInputs && optionalFormInputs) {
      const requiredKeys = requiredFormInputs.map(({ key }) => key);
      const optionalKeys = optionalFormInputs.map(({ key }) => key);
      const selectedKeys = Object.keys(formValues.searchFieldValues);
      const unsupportedKeys = selectedKeys?.filter((k) => (
        !requiredKeys.includes(k) && !optionalKeys.includes(k)));

      if (unsupportedKeys?.length > 0) {
        const updatedValues = { ...formValues.searchFieldValues };

        unsupportedKeys.forEach((key) => {
          delete updatedValues[key];
        });

        setFormValues({ ...formValues, searchFieldValues: updatedValues });
      }
    }
  }, [requiredFormInputs, optionalFormInputs]);

  const handleFormValues = (value, key) => {
    const updatedValues = { ...formValues.searchFieldValues };

    if (!value || value?.length === 0) {
      delete updatedValues[key];
      setFormValues({ ...formValues, searchFieldValues: updatedValues });
      return;
    }

    updatedValues[key] = value;
    setFormValues({ ...formValues, searchFieldValues: updatedValues });
  };

  const handleSearchType = (searchTypeName) => {
    setFormValues({ ...formValues, searchFieldValues: {}, resetKey: uniqid() });
    setSearchType(searchTypeName);
  };

  const resetForm = () => setFormValues({ searchFieldValues: {}, resetKey: uniqid() });

  let minStartDate = null;
  let showSearchNameInput = false;
  let keywordSearchName = null;

  const searchTypeDisplay = formConfig && (
    searchType
      ? _.find(formConfig.searchTypes, { name: searchType }).name
      : formConfig.searchTypes[0].name
  );
  const searchTypeId = formConfig && (
    searchType
      ? _.find(formConfig.searchTypes, { name: searchType }).id
      : formConfig.searchTypes[0].id
  );

  if (formConfigResult) {
    const keywordInput = _.find(formConfigResult, { key: 'keywords' });
    const keywordInputType = keywordInput?.fieldType;

    showSearchNameInput = !keywordInput || (keywordInputType && keywordInputType !== 'SINGLE_TEXT_INPUT');
  }

  if (_.find(formConfigResult, { key: 'title' }) !== undefined) {
    showSearchNameInput = false;
    keywordSearchName = formValues.searchFieldValues.title;
  }

  if (_.find(formConfigResult, { key: 'search_name' }) !== undefined) {
    showSearchNameInput = false;
    keywordSearchName = formValues.searchFieldValues.search_name;
  }

  if ('keywords' in formValues.searchFieldValues && !showSearchNameInput) {
    keywordSearchName = formValues.searchFieldValues.keywords;
  }

  if (formValues.searchFieldValues?.start_date_and_time) {
    minStartDate = formValues.searchFieldValues.start_date_and_time;
  }

  const requiredKeys = Array.isArray(requiredFormInputs)
    && requiredFormInputs.map(({ key }) => key);
  const requiredName = showSearchNameInput ? searchName : keywordSearchName;
  const submitEnabled = requiredKeys && requiredKeys.every((key) => (
    key in formValues.searchFieldValues
    && formValues.searchFieldValues[key].length > 0
    && requiredName
  ));

  /* eslint-disable camelcase */
  const handleFormSubmit = () => {
    setFormSubmitting(true);
    submitNewSearch(
      {
        search: {
          search_type: formValues.searchType,
          search_type_id: searchTypeId,
          name: requiredName,
          search_field_values: formValues.searchFieldValues,
        },
      },
      paths.productListSearches.replace(':status', 'live'),
      () => resetForm(),
    );
    setFormSubmitting(false);
  };
  /* eslint-enable camelcase */

  const renderFormContents = () => {
    if (loading) {
      return (
        <Box flex align="center" justify="center" background="white">
          <Spinning size="large" color={authPagesConfig.buttonHighlight} />
        </Box>
      );
    }

    return (
      <Box flex direction={small ? 'column' : 'row'} gap="2rem" justify={small ? 'start' : 'evenly'} pad={small ? '1.5rem' : '2rem'}>
        <ProductFormSectionWrapper
          small={small}
          sectionBg={authPagesConfig.altComponentBg}
          sectionTitle="Required Fields"
          titleColor={authPagesConfig.primaryText}
          borderColor={authPagesConfig.navBorder}
        >
          {Array.isArray(formConfig?.searchTypes) && formConfig.searchTypes.length > 1 && (
            <ProductFormFieldContainer
              small={small}
              fieldTitle="Search Type"
              primaryText={authPagesConfig.primaryText}
              hintText={authPagesConfig.hintText}
              borderColor={authPagesConfig.navBorder}
            >
              <ProductFormDropdownSelect
                selectable
                autoSelect
                authPagesConfig={authPagesConfig}
                label={searchTypeDisplay}
                handleFormValues={(searchTypeName) => handleSearchType(searchTypeName)}
                options={formConfig && formConfig.searchTypes}
              />
            </ProductFormFieldContainer>
          )}
          {showSearchNameInput && (
            <ProductFormFieldContainer
              small={small}
              fieldTitle="Search Name"
              description="Unique identifying name for this search"
              primaryText={authPagesConfig.primaryText}
              hintText={authPagesConfig.hintText}
              borderColor={authPagesConfig.navBorder}
            >
              <ProductFormSingleTextInput
                key={formValues.resetKey}
                focusHighlight={authPagesConfig.focusHighlight}
                small={small}
                value={searchName || ''}
                handleFormValues={(val) => setSearchName(val)}
              />
            </ProductFormFieldContainer>
          )}
          {Array.isArray(requiredFormInputs) && requiredFormInputs.map((dt) => (
            <DynamicFormRenderer
              key={dt.key}
              resetKey={formValues.resetKey}
              searchTypeKey={searchTypeKey}
              fieldData={dt}
              small={small}
              handleFormValues={(value, key) => handleFormValues(value, key)}
              formValues={formValues.searchFieldValues}
              authPagesConfig={authPagesConfig}
              today={today}
              minStartDate={minStartDate}
            />
          ))}
        </ProductFormSectionWrapper>
        <Box gap="2rem" width={{ min: '47%' }} direction="column">
          <ProductFormSectionWrapper
            small={small}
            sectionBg={authPagesConfig.altComponentBg}
            sectionTitle="Optional Fields"
            titleColor={authPagesConfig.primaryText}
            borderColor={authPagesConfig.navBorder}
          >
            {(Array.isArray(optionalFormInputs) && optionalFormInputs?.length > 0) ? (
              optionalFormInputs.map((dt) => (
                <DynamicFormRenderer
                  key={dt.key}
                  resetKey={formValues.resetKey}
                  searchTypeKey={searchTypeKey}
                  fieldData={dt}
                  small={small}
                  handleFormValues={(value, key) => handleFormValues(value, key)}
                  formValues={formValues.searchFieldValues}
                  authPagesConfig={authPagesConfig}
                  today={today}
                  minStartDate={minStartDate}
                />
              ))) : (
                <Box width="100%" align="center" justify="center" pad={{ top: '1rem' }}>
                  <Text size="1rem" color="#A2A2A2" textAlign="center">
                    No optional inputs for current selections
                  </Text>
                </Box>
            )}
          </ProductFormSectionWrapper>
          <Box direction="row" gap="1rem" justify="center">
            <AppButton
              width="6.5rem"
              onClick={() => resetForm()}
              level="dynamicLarge"
              color={authPagesConfig.buttonHighlight}
              fontWeight={600}
              label="Cancel"
            />
            <AppButton
              overrideHover
              disabled={!submitEnabled || formSubmitting}
              onClick={() => ((submitEnabled && !formSubmitting) ? handleFormSubmit() : null)}
              level="dynamicLarge"
              color="white"
              bgColor={authPagesConfig.buttonHighlight}
              fontWeight={600}
              label="Start Analysis"
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <AuthNavWrapper
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      reportsConfig={reportsConfig}
      cyclopsConfig={cyclopsConfig}
      bannerProps={{
        title: 'Create a new search',
        subTitle: searchTypeDisplay ? `Blacklight - ${searchTypeDisplay}` : 'Blacklight',
        textColor: 'white',
        bannerChildrenPosition: 'bottom',
      }}
    >
      <Seo />
      {renderFormContents()}
    </AuthNavWrapper>
  );
};

function mapStateToProps(state) {
  return {
    formConfig: state.productNewForm.formConfig,
    loading: state.fetchLoader.dataLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchFormConfig: initiateNewFormConfigRequest,
    submitNewSearch: submitNewSearchRequest,
  }, dispatch);
}

NewSearchPage.propTypes = {
  small: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  fetchFormConfig: PropTypes.func.isRequired,
  submitNewSearch: PropTypes.func.isRequired,
  formConfig: PropTypes.shape({
    searchTypes: PropTypes.arrayOf(PropTypes.shape({
      category: PropTypes.string,
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      fieldData: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        order: PropTypes.number.isRequired,
        config: PropTypes.objectOf(PropTypes.any),
        required: PropTypes.bool,
        fieldName: PropTypes.string.isRequired,
        fieldType: PropTypes.string.isRequired,
        selectable: PropTypes.bool,
        description: PropTypes.string,
      }).isRequired).isRequired,
    })),
  }),
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
  }),
  reportsConfig: PropTypes.arrayOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
};

export default connect(mapStateToProps, mapDispatchToProps)(withProductAuth(NewSearchPage));
