import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { Box, Text } from 'grommet';

import {
  Seo, FullHeightBox, AppButton,
} from '@Components/Control';
import { AuthBanner } from '@Components/Navigation';
import { WidgetRenderer, SearchDetails } from '@Components/Partial/Product/Dashboard';
import { withProductAuth } from '@Components/Layout';
import {
  initiateProductDashboardFlexibleReviewDataRequest,
  clearProductDashboardFlexibleReviewDataRequest,
  updateProductDashboardFlexibleReviewSelectionsRequest,
} from '@Actions';


const DashboardFlexibleResultsPage = ({
  small,
  size,
  authPagesConfig = null,
  loading,
  fetchFlexibleReviewData,
  clearFlexibleReviewData,
  flexibleReviewData = null,
  updateFlexibleReviewSelections,
}) => {
  const { id: pageUid } = useParams();
  const [reviewedData, setReviewedData] = React.useState([]);
  const [query, setQuery] = React.useState({
    pageUid,
    paging: {
      to: 1,
      size: 10,
    },
  });

  React.useEffect(() => {
    fetchFlexibleReviewData(pageUid, { ...query });

    return () => {
      clearFlexibleReviewData();
    };
  }, [query]);

  const handleQuery = (parentKey, updateKey, value) => {
    const prevQuery = { ...query };

    if (parentKey && !updateKey) {
      prevQuery[parentKey] = value;
    } else {
      prevQuery[parentKey][updateKey] = value;
    }

    setQuery(prevQuery);
  };

  const handleSort = (sortValue, sortDirection) => {
    const prevQuery = { ...query };

    if (!prevQuery.sort && sortValue) {
      prevQuery.sort = {};
    }

    if (sortValue === null && prevQuery.sort) {
      delete prevQuery.sort;
    } else if (sortValue) {
      prevQuery.sort = {
        name: sortValue,
        direction: sortDirection,
      };
    }

    setQuery(prevQuery);
  };

  const handleFilter = (
    filterKey, filterValue, filterPredicate, seperateValues = false, reset = false,
  ) => {
    const prevQuery = { ...query };

    if (reset) {
      delete prevQuery.filters;
      return setQuery(prevQuery);
    }

    if (!prevQuery.filters && filterValue) {
      prevQuery.filters = [];
    }

    const filterIndex = _.findIndex(prevQuery.filters, ['name', filterKey]);

    if (!filterValue && prevQuery.filters) {
      delete prevQuery.filters[filterIndex];
    } else if (filterValue && !seperateValues) {
      const newFilter = {
        name: filterKey,
        value: filterValue,
        predicate: filterPredicate,
      };

      if (filterIndex !== -1) {
        prevQuery.filters[filterIndex] = { ...newFilter };
      } else {
        prevQuery.filters.push(newFilter);
      }
    } else if (filterValue && seperateValues) {
      const addFilters = prevQuery.filters.concat(filterValue);
      prevQuery.filters = addFilters;
    }

    if (prevQuery.filters && prevQuery.filters.length === 0) {
      delete prevQuery.filters;
    }

    return setQuery(prevQuery);
  };

  /* eslint-disable camelcase */
  const initializeReviewedRows = (data) => {
    const reviewDataArr = data.map((dt) => {
      const reviewable = _.find(dt, ((row) => row.decoration === 'editable/dropdown'));
      const id = reviewable?.editableId;
      const api = reviewable?.api;

      return { id, review_status: api };
    });

    const filteredReviewable = _.filter(reviewDataArr, ((dt) => dt.id !== undefined));

    setReviewedData(filteredReviewable);
  };

  const updateRowReviewStatus = (editableId, selectedOption) => {
    const reviewDataArr = [...reviewedData];
    const editSelectionIndex = _.findIndex(reviewDataArr, { id: editableId });

    if (editSelectionIndex !== -1) {
      reviewDataArr[editSelectionIndex].review_status = selectedOption;
      setReviewedData(reviewDataArr);
    }
  };

  const submitEnabled = reviewedData.some(({ review_status }) => review_status !== 'pending' && review_status !== 'later');

  const sendReviewedResults = () => {
    const filteredSelections = _.filter(reviewedData, (({ review_status }) => review_status !== 'pending'));
    updateFlexibleReviewSelections({ data: filteredSelections }, () => {
      fetchFlexibleReviewData(pageUid, { ...query });
    });
    clearFlexibleReviewData();
    setReviewedData([]);
    window.scrollTo(0, 0);
  };
  /* eslint-enable camelcase */

  const { primaryText, buttonHighlight } = authPagesConfig;
  const padding = small ? '1.5rem' : '2rem';
  const pageTitle = flexibleReviewData?.search?.title || 'Search Results';
  const subTitle = flexibleReviewData?.search?.category || 'Blacklight';

  const withBannerDetails = flexibleReviewData?.search?.details
    && flexibleReviewData.search.details.length >= 1;
  const withAttributes = flexibleReviewData?.search?.attributes
    && flexibleReviewData.search.attributes.length >= 1;
  const reviewable = flexibleReviewData?.data && (flexibleReviewData.data.find((dt) => dt.feature === 'review/update') !== undefined);
  const totalResults = flexibleReviewData?.data && flexibleReviewData.data.find((dt) => dt.label === 'Total infringements');

  const resultsPerPageOptions = [10, 15, 20, 25];

  const filterSortMeta = {
    sort: {
      name: {
        options: [
          {
            name: 'view_count',
            formatted: 'View Count',
          },
        ],
      },
      direction: {
        options: [
          {
            name: 'asc',
            formatted: 'Ascending',
          },
          {
            name: 'desc',
            formatted: 'Descending',
          },
        ],
      },
    },
    filters: {
      options: [
        {
          name: 'view_count',
          formatted: 'View Count',
          validPredicates: ['gteq', 'lteq'],
        },
      ],
    },
    searchQueryKey: 'infringing_title',
  };

  return (
    <>
      <Seo />
      <FullHeightBox flex background="#F1F2FC" pad={{ top: small ? '5.2rem' : '3rem' }}>
        <AuthBanner
          navCollapsed
          adjustLeftPad
          small={small}
          image={authPagesConfig.bannerBg}
          textColor="white"
          title={pageTitle}
          subTitle={subTitle}
          bannerChildren={withBannerDetails ? (
            <SearchDetails small={small} details={flexibleReviewData.search.details} textColor="white" />
          ) : null}
          bannerChildrenPosition="bottom"
        />
        <Box flex pad={{ vertical: padding }}>
          {withAttributes && (
            <Box pad={{ horizontal: padding, bottom: padding }}>
              <SearchDetails attributes small={small} details={flexibleReviewData.search.attributes} textColor="#3C3C3C" weight={600} />
            </Box>
          )}
          <WidgetRenderer
            small={small}
            size={size}
            loading={loading}
            loaderColor={buttonHighlight}
            backgroundColor="white"
            textColor={primaryText}
            handleQuery={(parentKey, updateKey, value) => handleQuery(parentKey, updateKey, value)}
            handleSort={(sortValue, sortDirection) => handleSort(sortValue, sortDirection)}
            handleFilter={(key, value, predicate, seperateValues, reset) => (
              handleFilter(key, value, predicate, seperateValues, reset)
            )}
            filterSortMeta={filterSortMeta}
            searchQuery={query}
            widgetData={flexibleReviewData?.data}
            padding={padding}
            authPagesConfig={authPagesConfig}
            selectedStatuses={reviewedData}
            initializeReviewedRows={initializeReviewedRows}
            updateRowReviewStatus={updateRowReviewStatus}
            resultsPerPageOptions={resultsPerPageOptions}
            resultsPerPage={query.paging.size}
            currentPage={query.paging.to}
            totalResults={totalResults?.value}
          />
          {flexibleReviewData?.data && reviewable && (
            <Box
              width="100%"
              direction={small ? 'column' : 'row'}
              gap={small ? '1rem' : '2rem'}
              justify="between"
              align="center"
              pad={{ horizontal: padding, vertical: '2rem' }}
            >
              <Box width={small ? '100%' : '65%'}>
                <Text size="1rem" weight={500} color={authPagesConfig.primaryText}>
                  We will automatically send a DMCA notice to accounts marked as
                  infringements after submission.
                </Text>
              </Box>
              <Box pad={{ right: small ? '0rem' : '4rem' }}>
                <AppButton
                  overrideHover
                  width="10rem"
                  disabled={!submitEnabled || (reviewedData.length === 0)}
                  onClick={() => sendReviewedResults(reviewedData)}
                  level="dynamicLarge"
                  color="white"
                  bgColor={authPagesConfig.buttonHighlight}
                  fontWeight={600}
                  label="Submit Review"
                  title={submitEnabled ? null : 'Please select a status option'}
                />
              </Box>
            </Box>
          )}
        </Box>
      </FullHeightBox>
    </>
  );
};

function mapStateToProps(state) {
  return {
    flexibleReviewData: state.productDashboard.productFlexibleReviewData,
    loading: state.fetchLoader.dataLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchFlexibleReviewData: initiateProductDashboardFlexibleReviewDataRequest,
    clearFlexibleReviewData: clearProductDashboardFlexibleReviewDataRequest,
    updateFlexibleReviewSelections: updateProductDashboardFlexibleReviewSelectionsRequest,
  }, dispatch);
}

DashboardFlexibleResultsPage.propTypes = {
  small: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'mediumlarge', 'large']).isRequired,
  loading: PropTypes.bool.isRequired,
  fetchFlexibleReviewData: PropTypes.func.isRequired,
  clearFlexibleReviewData: PropTypes.func.isRequired,
  updateFlexibleReviewSelections: PropTypes.func.isRequired,
  flexibleReviewData: PropTypes.shape({
    query: PropTypes.shape({
      id: PropTypes.string.isRequired,
      paging: PropTypes.shape({
        to: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]).isRequired,
        size: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]).isRequired,
      }),
    }).isRequired,
    search: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      category: PropTypes.string,
      details: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.arrayOf(PropTypes.string.isRequired),
        ]).isRequired,
        type: PropTypes.string.isRequired,
        unit: PropTypes.string,
      })),
      attributes: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.arrayOf(PropTypes.string.isRequired),
        ]).isRequired,
        type: PropTypes.string.isRequired,
        unit: PropTypes.string,
      })),
    }).isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      type: PropTypes.string.isRequired,
      unit: PropTypes.string,
      feature: PropTypes.string,
    }).isRequired).isRequired,
  }),
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    bannerBg: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
  }),
};

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withProductAuth(DashboardFlexibleResultsPage));
